import * as React from "react";
import {
  Autocomplete,
  FormControlLabel,
  InputBase,
  NativeSelect,
  Radio,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchProfesores,
  select,
  updateSelectProf,
} from "../state/rootReducer.js";
import { GridCustom } from "./filtrogeneralperiodo.jsx";


const FiltroProfesores = ({ arrProfesores, fetchSaga }) => {
  const dispatch = useDispatch();
  const selectParam = useSelector(select);
  React.useEffect(() => {
    dispatch(fetchProfesores());
  }, [dispatch]);

  return (
    <>
      <GridCustom xs={2} sx={{ justifyContent: "left", paddingLeft: "2rem" }}>
        <FormControlLabel
          value="PPR"
          control={<Radio />}
          label="por profesor"
        />
      </GridCustom>

      <GridCustom>
        {selectParam === "PPR" && (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={arrProfesores.map((val, i) => {
              return { label: val.nombre.trim(), value: val.prof_id };
            })}
            sx={{ width: 500 }}
            renderInput={(params) => <TextField {...params}  />}
            onChange={(event, newValue) => {
                  console.log(newValue);
                  
                  if (newValue) {
                    dispatch(updateSelectProf(newValue.value));
                    dispatch(fetchSaga({ type: 'PPR', idProfesor: newValue.value }));
                  }
                }}
          />
          
        )}
      </GridCustom>
    </>
  );
};

export default FiltroProfesores;

// import { esES } from "@mui/material/locale";
// import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
// import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import dayjs from "dayjs";
// import updateLocale from "dayjs/plugin/updateLocale";
// import moment from "moment";
// import "dayjs/locale/es";
// // moment.updateLocale("es", {
// //   week: {
// //     dow: 1
// //   }
// // });

// const theme = createTheme(
//   {
//     palette: {
//       primary: { main: "#1976d2" },
//     },
//   },
//   esES // use 'de' locale for UI texts (start, next month, ...)
// );

// function App({ children }) {
//   return (
//     <ThemeProvider theme={theme}>
//       <LocalizationProvider dateAdapter={AdapterDayjs}>
//         <DatePicker
//           format="DD-MM-YYYY"
//           dayOfWeekFormatter={(day) => {
//             console.log(day);
//             return days[day];
//           }}
//         ></DatePicker>
//       </LocalizationProvider>{" "}
//     </ThemeProvider>
//   );
// }
// export default App;

import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material";
import { esES } from "@mui/x-date-pickers";
import { Provider } from "react-redux";
import store from "./state/store.js";
import Page from "./paginas/page.jsx";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#1976d2" },
    },
  },
  esES
);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Page></Page>
      </Provider>
    </ThemeProvider>
  );
}

export default App;

import React from "react";
import FiltroAnio from "./filtroanio.jsx";
import { GridCustom } from "./filtrogeneralperiodo.jsx";
import { LineChart } from "@mui/x-charts/LineChart";
import { useSelector } from "react-redux";
import { fetchProcGenerarGraficos, graf } from "../state/rootReducer.js";
import { Line } from "react-chartjs-2";
//import faker from "faker";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { blue, green, red } from "@mui/material/colors";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: false,
      text: "Chart.js Line Chart",
    },
  },
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      fill: true,
      label: "Dataset 2",
      data: [800, 200, 550, 220, 900, 620, 520], //labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

const Dashboard = ({ arrFiltroPeriodo }) => {
  const graficos = useSelector(graf);
  return (
    <>
      <FiltroAnio
        arrFiltroPeriodo={arrFiltroPeriodo}
        fetchSaga={fetchProcGenerarGraficos}
      ></FiltroAnio>
      <GridCustom container sx={{ height: "100%" }}>
        <GridCustom xs={5} sx={{ padding: ".5rem", height: "500px" }}>          
          <Line
            options={options}
            data={{
              labels: graficos.mes,
              datasets: [
                {
                  fill: true,
                  label: "Matriculados",
                  data: graficos.cursos, //labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                  borderColor: "rgb(53, 162, 235)",
                  backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
              ],
            }}
          />
        </GridCustom>
        <GridCustom xs={5} sx={{ padding: ".5rem", height: "500px" }}>
        <Line
            options={options}
            data={{
              labels: graficos.mes,
              datasets: [
                {
                  fill: true,
                  label: "Aprobados",
                  data: graficos.aprobados, //labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                  // borderColor: "rgb(53, 162, 235)",
                  // backgroundColor: "rgba(53, 162, 235, 0.5)",
                },
              ],
            }}
          />
          
        </GridCustom>
        <GridCustom xs={5} sx={{ padding: ".5rem", height: "500px" }}>
        <Line
            options={options}
            data={{
              labels: graficos.mes,
              datasets: [
                {
                  fill: true,
                  label: "Reprobados",
                  data: graficos.reprobados, //labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                  borderColor: red[600],
                  backgroundColor: red[200],
                },
              ],
            }}
          />
       
        </GridCustom>
        <GridCustom xs={5} sx={{ padding: ".5rem", height: "500px" }}>
        <Line
            options={options}
            data={{
              labels: graficos.mes,
              datasets: [
                {
                  fill: true,
                  label: "Pendientes",
                  data: graficos.pendientes, //labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
                  borderColor: green[600],
                  backgroundColor: green[200],
                },
              ],
            }}
          />
          
        </GridCustom>
      </GridCustom>
    </>
  );
};

export default Dashboard;

/*

          
          
      <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>      
            
            
          
        */

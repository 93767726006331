import * as React from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';



const Grilla = ({rows, columns}) => {
  
  return (
    <Box sx={{ height: 600, width: '100%', zIndex: 0, position: 'relative' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 15,
            },
          },
        }}
        pageSizeOptions={[15]}
        disableColumnFilter
        disableDensitySelector
        disableRowSelectionOnClick
        disableColumnSelector
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
    </Box>
  );
}
export default Grilla;
import { AppBar, Box, Button, IconButton, Toolbar } from "@mui/material";
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { Dropdown, DropdownMenuItem } from "./dropdown";
import { useDispatch } from "react-redux";
import { fetchPeriodosCursos, fetchProcGenerarGraficos, updateCurrent, updateTest } from "../state/rootReducer";
import config from "../configdata.js";

const Menu = ({ setTitulo }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    
      dispatch(fetchPeriodosCursos());
      dispatch(fetchProcGenerarGraficos({anio: '2023'}));

    }, [])
  const handleNav = (val) => {
    
    setTitulo(config.arrTitulos[val]);
    setOpen(false);
    dispatch(updateCurrent(val));
    
  };

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="static"
          style={{
            backgroundColor: "#fff",
            color: "#393C5A",
            fontFamily: "sans-serif",
          }}
        >
          <Toolbar>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 2 }}
              onClick={() => {
                dispatch(updateTest(2));
              }}
            >
              {/* <MenuIcon /> */}
            </IconButton>
            <Box
              alignContent={"flex-end"}
              justifyContent={"flex-end"}
              sx={{ width: "100%", display: "flex" }}
            >
              <Button
                sx={{ color: "#393C5A" }}
                onClick={() => {
                  dispatch(updateCurrent(0));
                  setTitulo(config.arrTitulos[0]);
                }}
              >
                Escritorio
              </Button>
              <Dropdown
                // keepOpen
                open={open}
                trigger={<Button>Reportes</Button>}
                menu={[
                  <DropdownMenuItem onClick={() => handleNav(1)}>
                    Cursos
                  </DropdownMenuItem>,
                  <DropdownMenuItem onClick={() => handleNav(2)}>
                    Profesores
                  </DropdownMenuItem>,
                  <DropdownMenuItem onClick={() => handleNav(3)}>
                    Cursos mas solicitados
                  </DropdownMenuItem>,
                  <DropdownMenuItem onClick={() => handleNav(4)}>
                  Alumnos con notas
                </DropdownMenuItem>,
                ]}
              />
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
};

export default Menu;

/*

          
          
      <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleClose}>My account</MenuItem>
              <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>      
            
            
          
        */

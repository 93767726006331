import { Box, Typography } from "@mui/material";
import React from "react";

const Contenedor = ({ titulo, children }) => {
  return (
    <div
      style={{ display: "flex", padding: "1rem", backgroundColor: "#F8F9FC", flexDirection: "column", gap: "1rem" }}
    >
      <Box
        sx={{
          backgroundColor: "#393C5A",
          
          height: "4rem",
          color: "#fff",
          textAlign: "left",
          alignItems: "center",
          display: "flex",
          paddingLeft: "2rem",
        }}
      >
        <Typography variant="h4" component="span" sx={{ flexGrow: 1 }}>
          {titulo}
        </Typography>
      </Box>
      {children}
      
    </div>
  );
};

export default Contenedor;

import axios from "axios";
import { SERVER } from "../configuration";


const MoodleService = {
    getReporteCursos: ({payload}) => {
        return axios.post(SERVER + "servicios/getReporteCursos.php", payload);
    },
    getPeriodosReporteCursos: (payload) => {
        return axios.post(SERVER + "servicios/getPeriodosReporteCursos.php", payload);
    },
    getProfesores: () => {        
        return axios.post(SERVER + "servicios/getProfesores.php");
    },
    getReporteProfesores: ({payload}) => {        
        return axios.post(SERVER + "servicios/getReporteProfesores.php", payload);
    },
    getReporteCursoMasSolicitado: ({payload}) => {        
        return axios.post(SERVER + "servicios/getReporteCursoMasSolicitado.php", payload);
    },
    getNotasAlumno: ({payload}) => {        
        return axios.post(SERVER + "servicios/getNotasAlumno.php", payload);
    },
    getReporteAlumnosConNota: ({payload}) => {        
        return axios.post(SERVER + "servicios/getReporteAlumnosConNota.php", payload);
    },
    getProyectos: () => {        
        return axios.post(SERVER + "servicios/getProyectos.php");
    },
    getCursos: () => {        
        return axios.post(SERVER + "servicios/getCursos.php");
    },
    login: ({payload}) => {        
        return axios.post(SERVER + "servicios/login.php", payload);
    },
    procGenerarGraficos:  ({payload}) => {        
        return axios.post(SERVER + "servicios/procGenerarGraficos.php", payload);
    },

    
}
export default MoodleService;
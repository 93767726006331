import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "@redux-saga/core";

import reportesReducer from "./rootReducer";
import rootSaga from "./saga/saga";
const sagaMiddleWare = createSagaMiddleware();

const store = configureStore({
  reducer: combineReducers({
    reportes: reportesReducer,
  }),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(sagaMiddleWare)
    
  },
});

sagaMiddleWare.run(rootSaga);

export default store;
import { all, call, put, takeEvery } from 'redux-saga/effects';
import MoodleService from '../../servicios/moodleService.js';
import { errorFetchingCursos, fetchReporteCursos, fetchPeriodosCursos, fetchProfesores, fetchReporteCursosMasSolicitado, fetchReporteProfesores, getPeriodosReporteCursos, getProfesores, getProyectos, getReporteCursoMasSolicitado, getReporteCursos, getReporteProfesores, getCursos, fetchCursos, getReporteAlumnosConNotas, fetchReporteAlumnosConNotas, fetchNotasAlumno, getNotasAlumnos, fetchLogin, getLogin, getGraficos, fetchProcGenerarGraficos } from '../rootReducer.js';

export function* loadReporteCursos(param) {
    try {
        const courses = yield call(MoodleService.getReporteCursos, param);
        yield put(getReporteCursos(courses));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* reporteCursoSaga() {
    yield takeEvery(fetchReporteCursos, loadReporteCursos);
}
//===================================================

export function* loadReporteCursoMasSolicitado(param) {
    try {
        const courses = yield call(MoodleService.getReporteCursoMasSolicitado, param);
        yield put(getReporteCursoMasSolicitado(courses));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* reporteCursoMasSolicitadoSaga() {
    yield takeEvery(fetchReporteCursosMasSolicitado, loadReporteCursoMasSolicitado);
}
//===================================================
export function* loadPeriodoReporteCursos() {
    try {
        const periodos = yield call(MoodleService.getPeriodosReporteCursos);
        yield put(getPeriodosReporteCursos(periodos));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* reportePeriodosCursoSaga() {
    yield takeEvery(fetchPeriodosCursos, loadPeriodoReporteCursos);
}
//===================================================

export function* loadProfesores() {
    try {
        const data = yield call(MoodleService.getProfesores);
        yield put(getProfesores(data));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* profesores() {
    yield takeEvery(fetchProfesores, loadProfesores);
}
//===================================================

export function* loadProyectos() {
    try {
        const data = yield call(MoodleService.getProyectos);
        yield put(getProyectos(data));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* proyectos() {
    yield takeEvery(fetchProfesores, loadProyectos);
}
//===================================================

export function* loadCursos() {
    try {
        const data = yield call(MoodleService.getCursos);
        yield put(getCursos(data));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* cursos() {
    yield takeEvery(fetchCursos, loadCursos);
}
//===================================================

export function* loadReporteProfesores(param) {
    try {
        const data = yield call(MoodleService.getReporteProfesores, param);
        yield put(getReporteProfesores(data));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* reporteProfesores() {
    yield takeEvery(fetchReporteProfesores, loadReporteProfesores);
}
//===================================================

export function* loadReporteAlumnosConNotas(param) {
    try {
        const data = yield call(MoodleService.getReporteAlumnosConNota, param);
        yield put(getReporteAlumnosConNotas(data));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* reporteAlumnosConNotas() {
    yield takeEvery(fetchReporteAlumnosConNotas, loadReporteAlumnosConNotas);
}
//===================================================


export function* loadNotasAlumno(param) {
    try {
        const data = yield call(MoodleService.getNotasAlumno, param);
        yield put(getNotasAlumnos(data));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* notas() {
    yield takeEvery(fetchNotasAlumno, loadNotasAlumno);
}
//===================================================

export function* loadLogin(param) {
    try {
        const data = yield call(MoodleService.login, param);
        yield put(getLogin(data));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* login() {
    yield takeEvery(fetchLogin, loadLogin);
}

//===================================================

export function* loadGraficos(param) {
    try {
        const data = yield call(MoodleService.procGenerarGraficos, param);
        yield put(getGraficos(data));
    } catch (err) {
        yield put(errorFetchingCursos());
    }
}

export function* graficos() {
    yield takeEvery(fetchProcGenerarGraficos, loadGraficos);
}
//===================================================

export default function* rootSaga() {
    yield all([
        reporteCursoSaga(),
        reportePeriodosCursoSaga(),
        reporteCursoMasSolicitadoSaga(),
        reporteProfesores(),
        reporteAlumnosConNotas(),
        profesores(),
        proyectos(),
        cursos(),
        notas(),
        login(),
        graficos(),
    ])
  }
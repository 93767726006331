import * as React from "react";
import {
  Autocomplete,
  FormControlLabel,
  Radio,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { fetchCursos, select, updateCurso } from "../state/rootReducer.js";
import { GridCustom } from "./filtrogeneralperiodo.jsx";

const FiltroCursos = ({ arrCursos, fetchSaga }) => {
  const dispatch = useDispatch();
  const selectParam = useSelector(select);
  React.useEffect(() => {
    dispatch(fetchCursos());
  }, [dispatch]);

  return (
    <>
      <GridCustom xs={2} sx={{ justifyContent: "left", paddingLeft: "2rem" }}>
        <FormControlLabel value="PCU" control={<Radio />} label="por curso" />
      </GridCustom>

      <GridCustom>
        {selectParam === "PCU" && (
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={arrCursos.map((val, i) => {
              return { label: `${val.fullname} (${val.id})`, value: val.id };
            })}
            sx={{ width: 1200 }}
            renderInput={(params) => <TextField {...params} />}
            onChange={(event, newValue) => {
              console.log(newValue);

              if (newValue) {
                dispatch(updateCurso(newValue.value));
                dispatch(fetchSaga({ type: "PCU", idCurso: newValue.value }));
              }
            }}
          />
        )}
      </GridCustom>
    </>
  );
};

export default FiltroCursos;

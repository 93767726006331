import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/CalendarMonth";
import {
  CalendarIcon,
  DateCalendar,
  LocalizationProvider,
  PickersDay,
} from "@mui/x-date-pickers";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Typography,
  useTheme,
} from "@mui/material";
import styled from "styled-components";
import dayjs from "dayjs";
import isBetweenPlugin from "dayjs/plugin/isBetween";
import updateLocale from "dayjs/plugin/updateLocale";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
dayjs.extend(isBetweenPlugin);
dayjs.extend(updateLocale);
dayjs.updateLocale("en", {
  weekStart: 1,
  months: [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ],
});

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "isSelected" && prop !== "isHovered",
})(({ isSelected, isHovered, day, posRange }) => {
  const theme = useTheme();
  console.log(posRange);
  return {
    borderRadius: 0,
    ...(isSelected && {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...(isHovered && {
      backgroundColor: theme.palette.primary[theme.palette.mode],
      "&:hover, &:focus": {
        backgroundColor: theme.palette.primary[theme.palette.mode],
      },
    }),
    ...(isSelected &&
      (posRange === "I" || posRange === "E") && {
        borderTopLeftRadius: "50%",
        borderBottomLeftRadius: "50%",
      }),
    ...(isSelected &&
      (posRange === "F" || posRange === "E") && {
        borderTopRightRadius: "50%",
        borderBottomRightRadius: "50%",
      }),
  };
});

function Day(props) {
  const { day, selectedDay, hoveredDay, operacion, ini, fin, ...other } = props;

  console.log(day.format("YYYY-MM-DD"));

  return (
    <CustomPickersDay
      {...other}
      day={day}
      posRange={
        day.format("YYYY-MM-DD") === ini.format("YYYY-MM-DD")
          ? day.format("YYYY-MM-DD") === fin.format("YYYY-MM-DD")
            ? "E"
            : "I"
          : fin && day.format("YYYY-MM-DD") === fin.format("YYYY-MM-DD")
          ? "F"
          : "R"
      }
      sx={{ px: 2.5 }}
      disableMargin
      selected={false}
      isSelected={operacion(day, selectedDay)}
      isHovered={operacion(day, hoveredDay)}
    />
  );
}

const days = {
  Mo: "Lu",
  Tu: "Ma",
  We: "Mi",
  Th: "Ju",
  Fr: "Vi",
  Sa: "Sa",
  Su: "Do",
};

export default function SelectorRango({ dispatchSaga }) {
  const [open, setOpen] = React.useState(false);
  const [hoveredDay, setHoveredDay] = React.useState(null);
  const [hoveredDay2, setHoveredDay2] = React.useState(null);
  const date = dayjs();
  const [value, setValue] = React.useState(dayjs(date.format("YYYY-MM-DD")));
  const [value2, setValue2] = React.useState(dayjs(date.format("YYYY-MM-DD")));

  const enRango = (day) => {
    if (day >= value && day <= value2) return true;
    return false;
  };

  const handleClose = () => {
    setOpen(false);
    dispatchSaga({
      type: "PC",
      periodo: `${value.format("YYYY-MM-DD")}|${value2.format("YYYY-MM-DD")}`,
    });
  };
  return (
    <>
      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: 400,
          borderStyle: "solid",
          borderWidth: "1px",
          borderColor: "#ccc",
          boxShadow: "none",
        }}
        onClick={() => setOpen(true)}
      >
        {/* <IconButton sx={{ p: '10px' }} aria-label="menu">
      <MenuIcon />
    </IconButton> */}
        <Typography
          sx={{ ml: 1, flex: 1, textAlign: "initial", color: "#555" }}
          inputProps={{ "aria-label": "search google maps" }}
        >
          {`desde ${value.format("DD-MM-YYYY")}`}
        </Typography>
        <Typography
          sx={{ ml: 1, flex: 1, textAlign: "initial", color: "#555" }}
          inputProps={{ "aria-label": "search google maps" }}
        >
          {`hasta ${value2.format("DD-MM-YYYY")}`}
        </Typography>
        <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
          <CalendarIcon />
        </IconButton>
        {/* <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
    <IconButton color="primary" sx={{ p: '10px' }} aria-label="directions">
      <DirectionsIcon />
    </IconButton> */}
      </Paper>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle>Seleccion personalizada</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Seleccione el periodo de interés
          </DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              m: "auto",
              width: "fit-content",
            }}
          >
            <FormControl
              sx={{
                mt: 2,
                minWidth: 120,
                display: "flex",
                flexDirection: "row",
                gap: "2rem",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar
                  value={value}
                  slots={{
                    day: (props) => (
                      <Day
                        {...props}
                        operacion={enRango}
                        ini={value}
                        fin={value2}
                      />
                    ),
                  }}
                  onChange={(newValue) => {
                    setValue(newValue);
                    setValue2(newValue);
                  }}
                  dayOfWeekFormatter={(day) => {
                    console.log(day);
                    return days[day];
                  }}
                  slotProps={{
                    day: (ownerState) => ({
                      selectedDay: value,
                      hoveredDay,
                      onPointerEnter: () => setHoveredDay(ownerState.day),
                      onPointerLeave: () => setHoveredDay(null),
                    }),
                  }}
                />
                <DateCalendar
                  value={value2}
                  onChange={(newValue) => {
                    if (
                      newValue.format("YYYY-MM-DD") < value.format("YYYY-MM-DD")
                    ) {
                      setValue(newValue);
                    }
                    setValue2(newValue);
                  }}
                  dayOfWeekFormatter={(day) => {
                    console.log(day);
                    return days[day];
                  }}
                  slots={{
                    day: (props) => (
                      <Day
                        {...props}
                        operacion={enRango}
                        ini={value}
                        fin={value2}
                      />
                    ),
                  }}
                  slotProps={{
                    day: (ownerState) => ({
                      selectedDay: value2,
                      hoveredDay: hoveredDay2,
                      onPointerEnter: () => setHoveredDay2(ownerState.day),
                      onPointerLeave: () => setHoveredDay2(null),
                    }),
                  }}
                />
              </LocalizationProvider>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Aceptar</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

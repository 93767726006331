import React, { useState } from "react";
import fondo from "../assets/img/fondo_login.png";
import logo from "../assets/img/ceim_logo.png";
import {
  Alert,
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  Paper,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { enviado, fetchLogin, login } from "../state/rootReducer";
const Login = () => {
  const [form, setForm] = useState({ user: "", pass: "" });
  const [warn, setWarn] = useState(false);
  const env = useSelector(enviado)
  const log_in = useSelector(login)
  const dispatch = useDispatch();
  
  const fnForm = (ev) => {
    setForm({ ...form, [ev.target.name]: ev.target.value });
  };
  const sendForm = () => {
    if (form.pass === "" || form.user === "") {
      setWarn(true);
    } else {
      setWarn(false);
      
      dispatch(fetchLogin(form));
    }
  };
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${fondo})`,
          height: "100vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {/* <img src={fondo} height={'1000px'}/> */}
        <Paper
          style={{
            display: "flex",
            flexDirection: "column",
            height: "40vh",
            width: "18vw",
            margin: "5vh",
            padding: "5rem",
            justifyContent: "center",

            gap: "2rem",
          }}
          elevation={2}
        >
          <div style={{ justifyContent: "center", display: "flex" }}>
            <img src={logo} width={"60%"} />
          </div>

          <Divider />
          {warn && (
            <Alert severity="warning">Debe completar ambos campos</Alert>
          )}
          {env && !log_in &&(
            <Alert severity="error">Su usuario o contraseña son incorrectos</Alert>
          )}

          <FormControl>
            <TextField
              id="outlined-basic"
              label="Usuario"
              variant="outlined"
              value={form.user}
              onChange={fnForm}
              name="user"
            />
          </FormControl>
          <FormControl>
            <TextField
              id="outlined-basic"
              label="Password"
              variant="outlined"
              type="password"
              onChange={fnForm}
              name="pass"
              value={form.pass}
            />
          </FormControl>
          <Button
            sx={{ backgroundColor: "#F6C23E", fontSize: ".7rem" }}
            size="large"
            variant="contained"
            onClick={sendForm}
          >
            Acceder
          </Button>
        </Paper>
      </div>
    </>
  );
};

export default Login;

import { createSlice } from "@reduxjs/toolkit";

export const reportesSlice = createSlice({
  name: "reportes",
  initialState: {
    reporte: [],
    graficos: {
      mes: [],
      cursos: [],
      aprobados: [],
      reprobados: [],
      pendientes: [],
    },
    notasAlumno: [],
    profesores: [],
    filtroPeriodo: [],
    proyectos: [],
    cursos: [],
    hasError: false,
    isLoading: false,
    current: 0,
    select: "PA",
    selectProf: "",
    curso: "",
    proyecto: "",
    login: false,
    enviado: false,
    test: 0,
  },
  reducers: {
    getReporteCursos: (state, action) => {
      state.reporte = action.payload.data.data;
      state.isLoading = false;
    },
    getCursos: (state, action) => {
      state.cursos = action.payload.data.data;
      state.isLoading = false;
    },
    getGraficos: (state, action) => {
      state.graficos = action.payload.data.data;
      state.isLoading = false;
    },
    getNotasAlumnos: (state, action) => {
      const arr = action.payload.data.data.map((el, i) => {
        let obj = { ...el };
        obj.id = i;
        return obj;
      });
      state.notasAlumno = arr;
      state.isLoading = false;
    },
    getProyectos: (state, action) => {
      state.proyectos = action.payload.data.data;
      state.isLoading = false;
    },
    getReporteCursoMasSolicitado: (state, action) => {
      state.reporte = action.payload.data.data;
      state.isLoading = false;
    },
    getReporteAlumnosConNotas: (state, action) => {
      let arr = action.payload.data.data;
      arr = arr.map((el, i) => {
        let obj = { ...el };
        obj.id = i;
        return obj;
      });
      state.reporte = arr;
      state.isLoading = false;
    },
    getReporteProfesores: (state, action) => {
      console.log(action.payload.data.data);
      let arr = action.payload.data.data;
      arr = arr.map((el, i) => {
        let obj = { ...el };
        obj.idCurso = el.id;
        obj.id = i;
        return obj;
      });
      state.reporte = arr;
      state.isLoading = false;
    },
    getProfesores: (state, action) => {
      state.profesores = action.payload.data.data;
      state.isLoading = false;
    },
    getLogin: (state, action) => {
      state.login = action.payload.data.login;
      state.enviado = true;
      state.isLoading = false;
    },
    getPeriodosReporteCursos: (state, action) => {
      let objPeriodos = {};
      for (let obj of action.payload.data.data) {
        let { timecreated } = obj;
        let [, mes, anio] = timecreated.split("-");
        if (!objPeriodos[anio]) {
          objPeriodos[anio] = { [mes]: mes };
        } else {
          if (!objPeriodos[anio][mes]) {
            objPeriodos[anio][mes] = mes;
          }
        }
      }

      /*let arr = Object.values(objPeriodos);
      for (let obj of arr) {
        obj = Object.values(obj);
      }*/
      state.filtroPeriodo = objPeriodos;
      state.isLoading = false;
    },

    errorFetchingCursos: (state) => {
      state.isLoading = false;
      state.hasError = true;
    },
    fetchReporteCursos: (state) => {
      state.reporte = [];
      state.isLoading = true;
    },
    fetchPeriodosCursos: (state) => {
      state.reporte = [];
      state.isLoading = true;
    },
    fetchProyectos: (state) => {
      state.proyectos = [];
      state.isLoading = true;
    },
    fetchCursos: (state) => {
      state.cursos = [];
      state.isLoading = true;
    },
    fetchProfesores: (state) => {
      state.isLoading = true;
      state.reporte = [];
      state.profesores = [];
    },
    fetchNotasAlumno: (state) => {
      state.isLoading = true;
      state.notasAlumno = [];
    },
    fetchReporteProfesores: (state) => {
      state.isLoading = true;
      state.reporte = [];
    },
    fetchReporteAlumnosConNotas: (state) => {
      state.isLoading = true;
      state.reporte = [];
    },
    fetchReporteCursosMasSolicitado: (state) => {
      state.isLoading = true;
      state.reporte = [];
    },
    fetchProcGenerarGraficos: (state) => {
      state.isLoading = true;
      state.graficos = {
        mes: [],
        cursos: [],
        aprobados: [],
        reprobados: [],
        pendientes: [],
      };
    },
    fetchLogin: (state) => {
      state.isLoading = true;
    },
    updateCurrent: (state, action) => {
      state.current = action.payload;
      state.reporte = [];
      state.profesores = [];
      // state.filtroPeriodo = [];
      state.select = "PA";
    },
    updateSelectProf: (state, action) => {
      state.selectProf = action.payload;
    },
    updateCurso: (state, action) => {
      state.selectProf = action.payload;
    },
    updateProyecto: (state, action) => {
      state.selectProf = action.payload;
    },
    updateSelect: (state, action) => {
      state.select = action.payload;
    },
    updateTest: (state, action) => {
      state.test = action.payload;
    },
  },
});

export const reporte = (state) => state.reportes.reporte;
export const isLoading = (state) => state.reportes.isLoading;
export const filtroPeriodo = (state) => state.reportes.filtroPeriodo;
export const profesores = (state) => state.reportes.profesores;
export const current = (state) => state.reportes.current;
export const selectProf = (state) => state.reportes.selectProf;
export const select = (state) => state.reportes.select;
export const proyectos = (state) => state.reportes.proyectos;
export const cursos = (state) => state.reportes.cursos;
export const notasAlumno = (state) => state.reportes.notasAlumno;
export const login = (state) => state.reportes.login;
export const enviado = (state) => state.reportes.enviado;
export const test = (state) => state.reportes.test;
export const graf = (state) => state.reportes.graficos;

// Action creators are generated for each case reducer function
export const {
  getReporteCursos,
  getLogin,
  getNotasAlumnos,
  getProfesores,
  getGraficos,
  getPeriodosReporteCursos,
  getReporteCursoMasSolicitado,
  getReporteAlumnosConNotas,
  getProyectos,
  errorFetchingCursos,
  fetchNotasAlumno,
  fetchReporteCursos,
  fetchPeriodosCursos,
  fetchProfesores,
  fetchProyectos,
  fetchCursos,
  fetchReporteAlumnosConNotas,
  fetchLogin,
  fetchProcGenerarGraficos,
  getCursos,
  fetchReporteCursosMasSolicitado,
  fetchReporteProfesores,
  updateCurrent,
  updateCurso,
  updateProyecto,
  updateSelectProf,
  getReporteProfesores,
  updateSelect,
  updateTest,
} = reportesSlice.actions;

export default reportesSlice.reducer;

import * as React from "react";
import {
  FormControl,
  FormControlLabel,
  InputBase,
  NativeSelect,
  Paper,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import config from "../configdata.js";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2.js";
import SelectorRango from "./selectorrango.jsx";
import { select, updateSelect } from "../state/rootReducer.js";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: (theme) => theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  //backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  // ...theme.typography.body2,
  padding: (theme) => theme.spacing(1),
  textAlign: "center",
  //color: theme.palette.text.secondary,
}));

export const GridCustom = styled(Grid2)(({ theme }) => ({
  display: "flex",
}));

const FiltroGeneralPeriodo = ({
  arrFiltroPeriodo,
  fetchSaga,
  children
}) => {
  const [anio, setAnio] = React.useState(null);
  const [anioHasta, setAnioHasta] = React.useState(null);
  const [mes, setMes] = React.useState(null);
  const [mesHasta, setMesHasta] = React.useState(null);
  const selectParam = useSelector(select);
  const [hasta, setHasta] = React.useState(null);
  const dispatch = useDispatch();

  const dispatchSaga = (val) => {
    dispatch(fetchSaga(val));
  };

  return (
    <Paper
      sx={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        paddingBlock: "1rem",
      }}
    >
      
      <FormControl style={{ display: "flex", width: "100%" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="PA"
          name="radio-buttons-group"
          onChange={(event) => dispatch(updateSelect(event.target.value))}
        >
          <GridCustom container spacing={2} sx={{ justifyContent: "left" }}>
            <GridCustom
              xs={2}
              sx={{ justifyContent: "left", paddingLeft: "2rem" }}
            >
              <FormControlLabel
                value="PA"
                control={<Radio />}
                label="por año"
              />
            </GridCustom>
            <GridCustom
              xs={10}
              sx={{ justifyContent: "left", paddingLeft: "1rem" }}
            >
              {selectParam === "PA" && (
                <NativeSelect
                  style={{ width: "8rem" }}
                  id="demo-customized-select-native"
                  input={<BootstrapInput />}
                  onChange={(event) => {
                    console.log(event.target.value);
                    if (event.target.value !== "") {
                      // setAnio(event.target.value);
                      // setShowMes(true);
                      dispatchSaga({
                        type: "PA",
                        periodo: `${event.target.value}`,
                      });
                    }
                  }}
                >
                  <option value="">
                    <em></em>
                  </option>
                  {arrFiltroPeriodo &&
                    Object.keys(arrFiltroPeriodo).map((val, i) => {
                      return (
                        <option key={i} value={val}>
                          {val}
                        </option>
                      );
                    })}
                </NativeSelect>
              )}
            </GridCustom>
            <GridCustom
              xs={2}
              sx={{ justifyContent: "left", paddingLeft: "2rem" }}
            >
              <FormControlLabel
                value="PP"
                control={<Radio />}
                label="por periodo"
              />
            </GridCustom>
            <GridCustom
              xs={10}
              sx={{
                alignItems: "center",
                justifyContent: "left",
                paddingLeft: "1rem",
                gap: "1rem",
              }}
            >
              {selectParam === "PP" && (
                <>
                  Desde:
                  <NativeSelect
                    style={{ width: "8rem" }}
                    id="demo-customized-select-native"
                    input={<BootstrapInput />}
                    onChange={(event) => {
                      console.log(event.target.value);
                      if (event.target.value !== "") {
                        setAnio(event.target.value);
                      }
                    }}
                  >
                    <option value="">
                      <em></em>
                    </option>
                    {arrFiltroPeriodo &&
                      Object.keys(arrFiltroPeriodo).map((val, i) => {
                        return (
                          <option key={i} value={val}>
                            {val}
                          </option>
                        );
                      })}
                  </NativeSelect>
                  <NativeSelect
                    style={{ width: "8rem" }}
                    id="demo-customized-select-native"
                    input={<BootstrapInput />}
                    onChange={(event) => {
                      if (event.target.value !== "") {
                        setHasta(true);
                        setMes(event.target.value);
                        // setMes(event.target.value)
                        // setPeriodo(`${anio}-${event.target.value}`)
                        // dispatch(
                        //   fetchSaga({ periodo: `${anio}-${event.target.value}` })
                        // );
                        // setExtras(true);
                      }
                    }}
                  >
                    <option value="">
                      <em></em>
                    </option>
                    {arrFiltroPeriodo &&
                      arrFiltroPeriodo[anio] &&
                      Object.entries(arrFiltroPeriodo[anio])
                        .sort((a, b) => {
                          return Number(a[0]) > Number(b[0]) ? true : false;
                        })
                        .map((val, i) => {
                          return (
                            <option key={i} value={val[1]}>
                              {config.arrMeses[val[1] - 1]}
                            </option>
                          );
                        })}
                  </NativeSelect>
                  Hasta
                  <NativeSelect
                    style={{ width: "8rem" }}
                    id="demo-customized-select-native"
                    input={<BootstrapInput />}
                    disabled={!hasta}
                    onChange={(event) => {
                      console.log(event.target.value);
                      if (event.target.value !== "") {
                        setAnioHasta(event.target.value);
                      }
                    }}
                  >
                    <option value="">
                      <em></em>
                    </option>
                    {arrFiltroPeriodo &&
                      Object.keys(arrFiltroPeriodo)
                        .filter((a) => {
                          return Number(a) >= Number(anio);
                        })
                        .map((val, i) => {
                          return (
                            <option key={i} value={val}>
                              {val}
                            </option>
                          );
                        })}
                  </NativeSelect>
                  <NativeSelect
                    style={{ width: "8rem" }}
                    id="demo-customized-select-native"
                    input={<BootstrapInput />}
                    disabled={!hasta}
                    onChange={(event) => {
                      if (event.target.value !== "") {
                        setMesHasta(event.target.value)
                        
                        dispatchSaga({ type: 'PP', periodo: `${anio}-${mes}|${anioHasta}-${event.target.value}` })
                        
                        
                      }
                    }}
                  >
                    <option value="">
                      <em></em>
                    </option>
                    {arrFiltroPeriodo &&
                      arrFiltroPeriodo[anioHasta] &&
                      Object.entries(arrFiltroPeriodo[anioHasta])
                        .filter((a) => {
                          return anio === anioHasta
                            ? Number(a[0]) >= Number(mes)
                            : true;
                        })
                        .sort((a, b) => {
                          return Number(a[0]) > Number(b[0]) ? true : false;
                        })
                        .map((val, i) => {
                          return (
                            <option key={i} value={val[1]}>
                              {config.arrMeses[val[1] - 1]}
                            </option>
                          );
                        })}
                  </NativeSelect>
                </>
              )}
            </GridCustom>
            <GridCustom
              xs={2}
              sx={{
                alignItems: "center",
                justifyContent: "left",
                paddingLeft: "2rem",
              }}
            >
              <FormControlLabel
                value="PC"
                control={<Radio />}
                label="Personalizado"
              />
            </GridCustom>
            <GridCustom
              xs={10}
              sx={{
                alignItems: "center",
                justifyContent: "left",
                paddingLeft: "2rem",
              }}
            >
              {selectParam === "PC" && (
                <SelectorRango dispatchSaga={dispatchSaga}></SelectorRango>
              )}
            </GridCustom>
            {children}
          </GridCustom>
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

export default FiltroGeneralPeriodo;

import * as React from "react";
import {
  FormControl,
  InputBase,
  InputLabel,
  NativeSelect,
} from "@mui/material";
import styled from "styled-components";
import { useDispatch } from "react-redux";

export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: (theme) => theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    fontSize: 16,
    padding: "10px 26px 10px 12px",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      borderRadius: 4,
      borderColor: "#80bdff",
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
    },
  },
}));

const FiltroAnio = ({
  arrFiltroPeriodo,
  fetchSaga,
  children,
  setPeriodo,
  current,
}) => {
  const [anio, setAnio] = React.useState('2023');
  const dispatch = useDispatch();
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
      <FormControl sx={{ m: 1 }} variant="standard">
        <NativeSelect
          id="demo-customized-select-native"
          input={<BootstrapInput value={anio} />}
          value={anio}
          onChange={(event) => {
            console.log(event.target.value);
            if (event.target.value !== "") {
              setAnio(event.target.value);
              dispatch(fetchSaga({ anio: event.target.value }));
            }
          }}
        >
          <option value="">
            <em></em>
          </option>
          {Object.keys(arrFiltroPeriodo).map((val, i) => {
            return (
              <option key={i} value={val}>
                {val}
              </option>
            );
          })}
        </NativeSelect>
      </FormControl>
    </div>
  );
};

export default FiltroAnio;

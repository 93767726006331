import { useState } from "react";
import Menu from "../componentes/menu.jsx";
import Contenedor from "../componentes/contenedor.jsx";
import Grilla from "../componentes/grilla.jsx";
import config from "../configdata.js/index.js";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReporteCursos,
  filtroPeriodo,
  isLoading,
  profesores,
  current,
  fetchReporteProfesores,
  fetchReporteCursosMasSolicitado,
  cursos,
  fetchReporteAlumnosConNotas,
  notasAlumno,
  fetchNotasAlumno,
  reporte,
  login,
  
} from "../state/rootReducer.js";
import CircularProgress from "@mui/material/CircularProgress";

import FiltroProfesores from "../componentes/filtroprofesores.jsx";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
  useTheme,
} from "@mui/material";
import FiltroGeneralPeriodo, {
  GridCustom,
} from "../componentes/filtrogeneralperiodo.jsx";
import FiltroCursos from "../componentes/filtrocursos.jsx";
import styled from "styled-components";
import FiltroAnio from "../componentes/filtroanio.jsx";
import Dashboard from "../componentes/dashboard.jsx";
import Login from "./login.jsx";

const StyledTableCell = styled(TableCell)(() => {
  const theme = useTheme();
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#393C5A",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  };
});

const Page = () => {
  const currentParam = useSelector(current);
  // const testx = useSelector(test);
  const [periodo, setPeriodo] = useState("");
  const [titulo, setTitulo] = useState("Escritorio");
  const arrReporte = useSelector(reporte);
  const arrFiltroPeriodo = useSelector(filtroPeriodo);
  const arrProfesores = useSelector(profesores);
  const arrCursos = useSelector(cursos);
  const arrNotasAlumno = useSelector(notasAlumno);
  const log_in = useSelector(login);
  const is_Loading = useSelector(isLoading);
  const [popupNotas, setPopupNotas] = useState({
    open: false,
    titulo: "",
    nombre: "",
    estado: "",
    rut: "",
    email: "",
  });
  
  const dispatch = useDispatch();
  const actConNotas = {
    field: "",
    headerName: "Accion",
    width: 100,
    renderCell: (params) => {
      console.log(params);
      return (
        <strong>
          <Button
            onClick={() => {
              setPopupNotas({
                titulo: `${params.row.Curso} - ${params.row.cid}`,
                open: true,
                nombre: `${params.row.firstname} ${params.row.lastname}`,
                rut: params.row.username,
                email: params.row.email,
                estado: params.row.estado,
              });
              dispatch(
                fetchNotasAlumno({ Uid: params.row.Uid, Curso: params.row.cid })
              );
            }}
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            tabIndex={params.hasFocus ? 0 : -1}
          >
            Open
          </Button>
        </strong>
      );
    },
  };

  return (
    <>
      {!log_in ? (
        <Login></Login>
      ) : (
        <>
          <div style={{ position: "absolute", width: "100%", height: "100%" }}>
            {is_Loading && (
              <div
                style={{
                  zIndex: 2,
                  position: "absolute",
                  backgroundColor: "#000",
                  width: "100%",
                  height: "100%",
                  opacity: 0.3,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress style={{ color: "#fff" }}></CircularProgress>
              </div>
            )}
            <div className="App">
              <Menu setTitulo={setTitulo}></Menu>
              <Contenedor titulo={titulo}>
                {currentParam === 0 && (
                  <>
                    <Dashboard arrFiltroPeriodo={arrFiltroPeriodo} />
                  </>
                )}
                {currentParam === 1 && (
                  <>
                    <FiltroGeneralPeriodo
                      arrFiltroPeriodo={arrFiltroPeriodo}
                      fetchSaga={fetchReporteCursos}
                    ></FiltroGeneralPeriodo>
                    {/* <FiltroPeriodo
                arrFiltroPeriodo={arrFiltroPeriodo}
                fetchSaga={fetchReporteCursos}
                setPeriodo={setPeriodo}
                current={current}
              ></FiltroPeriodo> */}
                    <Box
                      sx={{
                        backgroundColor: "#858796",

                        height: "4rem",
                        color: "#fff",
                        textAlign: "left",
                        alignItems: "center",
                        display: "flex",
                        paddingLeft: "2rem",
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="span"
                        sx={{ flexGrow: 1 }}
                      >
                        Cursos
                      </Typography>
                    </Box>
                    <div>
                      <Grilla
                        columns={config.columnsReporteCursos}
                        rows={arrReporte}
                      ></Grilla>
                    </div>
                  </>
                )}
                {currentParam === 2 ? (
                  <>
                    <FiltroGeneralPeriodo
                      arrFiltroPeriodo={arrFiltroPeriodo}
                      fetchSaga={fetchReporteProfesores}
                    >
                      <FiltroProfesores
                        arrProfesores={arrProfesores}
                        periodo={periodo}
                        fetchSaga={fetchReporteProfesores}
                      ></FiltroProfesores>
                    </FiltroGeneralPeriodo>
                    {/* <FiltroPeriodo
                arrFiltroPeriodo={arrFiltroPeriodo}
                fetchSaga={fetchProfesores}
                setPeriodo={setPeriodo}
                current={current}
              >
                <FiltroProfesores
                  arrProfesores={arrProfesores}
                  periodo={periodo}
                ></FiltroProfesores>
              </FiltroPeriodo> */}
                    <Box
                      sx={{
                        backgroundColor: "#858796",

                        height: "4rem",
                        color: "#fff",
                        textAlign: "left",
                        alignItems: "center",
                        display: "flex",
                        paddingLeft: "2rem",
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="span"
                        sx={{ flexGrow: 1 }}
                      >
                        Profesores
                      </Typography>
                    </Box>
                    <div>
                      <Grilla
                        columns={config.columnsReporteProfesores}
                        rows={arrReporte}
                      ></Grilla>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                {currentParam === 3 && (
                  <>
                    <FiltroGeneralPeriodo
                      arrFiltroPeriodo={arrFiltroPeriodo}
                      fetchSaga={fetchReporteCursosMasSolicitado}
                    ></FiltroGeneralPeriodo>
                    {/* <FiltroPeriodo
                arrFiltroPeriodo={arrFiltroPeriodo}
                fetchSaga={fetchReporteCursos}
                setPeriodo={setPeriodo}
                current={current}
              ></FiltroPeriodo> */}
                    <Box
                      sx={{
                        backgroundColor: "#858796",

                        height: "4rem",
                        color: "#fff",
                        textAlign: "left",
                        alignItems: "center",
                        display: "flex",
                        paddingLeft: "2rem",
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="span"
                        sx={{ flexGrow: 1 }}
                      >
                        Cursos mas solicitados
                      </Typography>
                    </Box>
                    <div>
                      <Grilla
                        columns={config.columnsReporteCursoMasSolicitado}
                        rows={arrReporte}
                      ></Grilla>
                    </div>
                  </>
                )}
                {currentParam === 4 && (
                  <>
                    <FiltroGeneralPeriodo
                      arrFiltroPeriodo={arrFiltroPeriodo}
                      fetchSaga={fetchReporteAlumnosConNotas}
                    >
                      <FiltroCursos
                        arrCursos={arrCursos}
                        fetchSaga={fetchReporteAlumnosConNotas}
                      ></FiltroCursos>
                    </FiltroGeneralPeriodo>

                    <Box
                      sx={{
                        backgroundColor: "#858796",

                        height: "4rem",
                        color: "#fff",
                        textAlign: "left",
                        alignItems: "center",
                        display: "flex",
                        paddingLeft: "2rem",
                      }}
                    >
                      <Typography
                        variant="h5"
                        component="span"
                        sx={{ flexGrow: 1 }}
                      >
                        Cursos mas solicitados
                      </Typography>
                    </Box>
                    <div>
                      <Grilla
                        columns={[
                          ...config.columnsReporteAlumnosConNotas,
                          actConNotas,
                        ]}
                        rows={arrReporte}
                      ></Grilla>
                    </div>
                  </>
                )}
                <Dialog
                  fullWidth={true}
                  maxWidth={"lg"}
                  open={popupNotas.open}
                  // onClose={() => setOpen(false)}
                >
                  <DialogTitle>{`Notas del curso (${popupNotas.titulo})`}</DialogTitle>
                  <DialogContent>
                    <GridCustom container>
                      <GridCustom xs={12} sx={{ padding: ".5rem" }}>
                        <TextField
                          label="Nombre"
                          defaultValue={popupNotas.nombre}
                          style={{
                            width: "100%",
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                        ></TextField>
                      </GridCustom>
                      <GridCustom xs={6} sx={{ padding: ".5rem" }}>
                        <TextField
                          label="Rut"
                          defaultValue={popupNotas.rut}
                          style={{
                            width: "100%",
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                        ></TextField>
                      </GridCustom>
                      <GridCustom xs={6} sx={{ padding: ".5rem" }}>
                        <TextField
                          label="Estado"
                          defaultValue={popupNotas.estado}
                          style={{
                            width: "100%",
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                        ></TextField>
                      </GridCustom>
                      <GridCustom xs={12} sx={{ padding: ".5rem" }}>
                        <TextField
                          label="Email"
                          defaultValue={popupNotas.email}
                          style={{
                            width: "100%",
                          }}
                          InputProps={{
                            readOnly: true,
                          }}
                        ></TextField>
                      </GridCustom>
                      <GridCustom xs={12} sx={{ padding: ".5rem" }}>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow>
                                <StyledTableCell>
                                  Nombre Evaluación
                                </StyledTableCell>
                                <StyledTableCell align="right">
                                  Nota
                                </StyledTableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {arrNotasAlumno.map((row) => (
                                <TableRow
                                  key={row.id}
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                  }}
                                >
                                  <TableCell component="th" scope="row">
                                    {row.itemname}
                                  </TableCell>
                                  <TableCell align="right">
                                    {row.finalgrade}
                                  </TableCell>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </GridCustom>
                    </GridCustom>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() =>
                        setPopupNotas({ ...popupNotas, open: false })
                      }
                    >
                      Aceptar
                    </Button>
                  </DialogActions>
                </Dialog>
              </Contenedor>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Page;

import { Button } from "@mui/material";

const config = {
  columnsReporteCursos: [
    { field: "shortname", headerName: "ID Proyecto", width: 120 },
    {
      field: "id",
      headerName: "ID Curso",
      type: "number",
      width: 150,
    },
    {
      field: "fullname",
      headerName: "Nombre Completo",
      width: 600,
    },

    {
      field: "timecreated",
      headerName: "Creacion",
      width: 160,
    },
    {
      field: "startdate",
      headerName: "Inicio",
      width: 110,
    },
    {
      field: "enddate",
      headerName: "Fin",
      width: 110,
    },
    {
      field: "ALUMNOS",
      headerName: "Alumnos",
      type: "number",
      width: 110,
    },
    {
      field: "PROFESORES",
      headerName: "Profesores",
      type: "number",
      width: 110,
    },
    {
      field: "codsence",
      headerName: "Sence",
      width: 110,
    },

    {
      field: "ENCUESTA",
      headerName: "Encuesta",
      width: 110,
    },
  ],
  columnsReporteCursoMasSolicitado: [
    { field: "id", headerName: "ID Curso", width: 90 },
    { field: "shortname", headerName: "Nombre corto", width: 120 },
    {
      field: "fullname",
      headerName: "Nombre Curso",
      width: 600,
    },
    {
      field: "creacion",
      headerName: "Creacion",
      width: 160,
    },
    {
      field: "inicio",
      headerName: "Inicio",
      width: 110,
    },
    {
      field: "ult_mod",
      headerName: "Modificación/Actualización",
      width: 110,
    },   
    
    {
      field: "cant_alum",
      headerName: "Alumnos",
      type: "number",
      width: 110,
    },
    
  ],
  columnsReporteAlumnosConNotas: [
    { field: "Uid", headerName: "ID Usuario", width: 90 },
    { field: "username", headerName: "Rut", width: 120 },
    {
      field: "firstname",
      headerName: "Nombre",
      width: 300,
    },
    {
      field: "lastname",
      headerName: "Apellido",
      width: 300,
    },
    {
      field: "email",
      headerName: "Mail",
      width: 110,
    },
    {
      field: "idProyecto",
      headerName: "N° Proyecto",
      width: 110,
    },   
    
    {
      field: "Curso",
      headerName: "Curso",
      width: 400,
    },
    
    
  ],
  columnsReporteProfesores: [
    {
      field: "idCurso",
      headerName: "ID Curso",
      resizable: true,
    },
    {
      field: "shortname",
      headerName: "ID Proyecto",
      resizable: true,
    },
    {
      field: "fullname",
      headerName: "Curso",
      width: 600,
      valueGetter: ({row}) => {
        console.log(row, row.fullname)
        return row.fullname + "(" + row.idCurso + ")";
      },
      resizable: true,
    },
    {
      field: "firstname",
      headerName: "Nombre",
      width: 200,
      resizable: true,
    },

    {
      field: "lastname",
      headerName: "Apellido",
      width: 200,
      resizable: true,
    },
    {
      field: "username",
      headerName: "Rut",
      resizable: true,
    },
    {
      field: "phone1",
      headerName: "Telefono",
      resizable: true,
    },
    {
      field: "email",
      headerName: "Email",
      width: 300,
      resizable: true,
    },
    {
      field: "startdate",
      headerName: "Inicio",
      resizable: true,
    },
    {
      field: "enddate",
      headerName: "Termino",
      resizable: true,
    },
  ],
  columnsNotasAlumno: [
    {
      field: "itemname",
      headerName: "Nombre Evaluación",
      resizable: true,
      width: "80%",
    },
    {
      field: "finalgrade",
      headerName: "Nota",
      resizable: true,
      width: "20%",
    },
    
  ],
  arrMeses: [
    "enero",
    "febrero",
    "marzo",
    "abril",
    "mayo",
    "junio",
    "julio",
    "agosto",
    "septiembre",
    "octubre",
    "noviembre",
    "diciembre",
  ],
  arrTitulos: ["Escritorio", "Reporte Cursos", "Reporte Profesores", "Reporte cursos más solicitados", "Reporte alumnos con notas"],
};

export default config;
